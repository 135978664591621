
//拼接字符串---直播地址的
//直播会议---会议管理
export function live_room_link(id) {
   return 'https://yunicu-mp.yunicu.com/conference?convention_id='+ id
}
export function live_room_link_case(url) {
   return 'https://yunicu-mp.yunicu.com'+ url
}
// //测试头
// export function live_link(id) {
//    return 'http://live-h5.we-fire.cn/#/chat-online?room='+ id
// }
//正式头
export function live_link(id) {
   return 'https://live-h5.yunicu.com/#/chat-online?room='+ id
}
//分割字符串
export function live_link_id(url) {
   let obj = {}
   if (url.indexOf('?') < 0) return obj
   let arr = url.split('?')
   url = arr[1]
   let array = url.split('&')
   for (let i = 0; i < array.length; i++) {
      let arr2 = array[i]
      let arr3 = arr2.split('=')
      obj[arr3[0]] = arr3[1]
   }
   return obj
}